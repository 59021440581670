import React from "react"
import "./Filter.scss"

const Filter = ({ categories, filterWork, activeFilterKey }) => {
    return (
        <ul className="filter">
            <li
                role="button"
                onClick={() => filterWork(null)}
                aria-label="Change filter"
                className={`filter-item link ${activeFilterKey == null ? "active" : ""}`}
                tabIndex={0}
            >
                <span>All</span>
            </li>

            {categories &&
            categories.map((category, index) => (
                <li
                    role="button"
                    key={index}
                    onClick={() => filterWork(category.slug)}
                    aria-label="Change filter"
                    className={`filter-item link ${activeFilterKey == category.slug ? "active" : ""}`}
                    tabIndex={index + 1}
                >
                    <span>{category.name}</span>
                </li>
            ))}
        </ul>
    )
}
export default Filter
