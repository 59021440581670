import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import WorkItem from "../components/templates/home/WorkItem"
import Filter from "../components/elements/filter/Filter"
import {
    CSSTransition, SwitchTransition,
} from "react-transition-group"
import "./index.scss"
import SEO from "../components/elements/SEO"
import { DelayedRenderer } from "react-delayed-renderer"
import { Masonry } from "masonic"
import useWindowSize from "../hooks/useWindowSize"

const MOBILE_BREAKPOINT = 380

export const query = graphql`
    {
        wpPage {
            id
            title
        }
        wp {
            generalSettings {
                url
            }
             seo {
                 openGraph {
                    defaultImage {
                      sourceUrl
                    }
                  }
                contentTypes {
                    post {
                        schema {
                            raw
                        }
                    }
                }
            }
        }
        allWpCategory(filter: {count: {gt: 0}}) {
            nodes {
                slug
                name
            }
        }
        allWpPost {
            nodes {
                status
                databaseId
                title
                uri
                work {
                    ondertitel
                    afbeelding {
                        srcSet
                        sourceUrl
                        mediaDetails {
                            height
                            width
                            sizes {
                                file
                                sourceUrl
                                name
                            }
                        }
                    }
                    featuredVideo {
                        guid
                        mediaDetails {
                            height
                            width
                        }
                    }
                }
                categories {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
    }
`

const Home = ({ data }) => {
    const posts = data.allWpPost.nodes
    const page = data.wpPage
    console.log(process.env.WORDPRESS_URL)
    console.log(process.env.FRONTEND_URL)
    const wordPressUrl = data.wp.generalSettings.url.replace(process.env.WORDPRESS_URL, process.env.FRONTEND_URL)
    const categories = data.allWpCategory.nodes
    const { width: windowWidth } = useWindowSize()
    const gutter = useRef(0)
    const [state, setState] = useState(
        {
            posts: posts.filter(({ status }) => status === "publish"),
            filtered: posts,
            filterKey: null,
            workRenderDelay: 1500,
        },
    )

    useEffect(() => {
        // gutter calculator for masonry
        gutter.current = document.getElementById("gutter-calculator").getBoundingClientRect().width
    }, [])


    const filterWork = (slug) => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 500)

        if (!!!slug) {
            setState({ ...state, filtered: state.posts, filterKey: slug })
        } else {
            let filter = state.posts.filter((post) => post.categories.nodes.map(cat => cat.slug).includes(slug))
            setState({ ...state, filtered: filter, filterKey: slug })
        }
    }

    return (
        <main className="container is-fluid">
            <SEO title={page.title} canonical={wordPressUrl} image={data.wp.seo.openGraph.defaultImage.sourceUrl} />

            <div className="page-home" id="page-home">
                <Filter categories={categories} filterWork={filterWork} activeFilterKey={state.filterKey} />

                <DelayedRenderer delay={state.workRenderDelay}>
                    <SwitchTransition
                        mode={"out-in"}
                    >
                        <CSSTransition
                            key={state.filterKey}
                            timeout={{
                                enter: 500,
                                exit: 500,
                            }}
                            appear={true}
                            classNames="work-container"
                        >
                            {posts ?
                                <Masonry className={"masonry"}
                                         style={windowWidth < (MOBILE_BREAKPOINT - 1) ? { marginTop: "2em" } : {}}
                                         columnCount={windowWidth > MOBILE_BREAKPOINT ? 2 : 1}
                                         overscanBy={posts.length}
                                         columnGutter={gutter.current}
                                         items={state.filtered} render={WorkItem}
                                /> : null}
                        </CSSTransition>
                    </SwitchTransition>
                </DelayedRenderer>
            </div>

            <div id="gutter-calculator" />
        </main>
    )
}

export default Home
