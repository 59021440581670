import React, { useEffect } from "react"
import LazyLoad from "vanilla-lazyload"
import  "./lazy-image.scss"
import cn from "classnames"

const LazyImage = (props) => {
    useEffect(() => {
        document.lazyLoadInstance.update()
    })

    const { alt, src, srcset, sizes, width, height } = props

    return (
        <img
            alt={alt}
            className={cn('lazy')}
            data-src={src}
            data-srcset={srcset}
            data-sizes={sizes}
            width={width}
            height={height}
        />
    )
}

export default LazyImage
