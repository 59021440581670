import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import "./WorkItem.scss"
import { getRandomClass } from "../../../utils"
import LazyImage from "../../lazy-image"

const RatioPreservingBox = styled.div`
  ${({ ratio }) => {
    return `padding-top: ${ratio * 100}%`
  }}
`

const WorkItem = ({ index, data }) => {
    let item
    const post = data

    if (post.work.featuredVideo) {
        const mediaDetails = post.work.featuredVideo.mediaDetails

        item =
            <RatioPreservingBox className="image-wrapper"
                                ratio={mediaDetails.height / mediaDetails.width}>
                <video
                    className="video-responsive"
                    playsInline
                    autoPlay
                    muted
                    loop
                    preload="auto"
                >
                    <source
                        src={post.work.featuredVideo.guid}
                        type="video/mp4"
                    />
                </video>
                <div className="overlay">
                    <p>
                        {post.title}
                        <br />
                        {post.work.ondertitel}
                    </p>
                </div>
            </RatioPreservingBox>
    } else {
        const mediaDetails = post.work.afbeelding.mediaDetails

        item =
            <RatioPreservingBox className="image-wrapper"
                                style={{ backgroundImage: `url(${mediaDetails.sizes.find(({ name }) => name === "blurred").sourceUrl})` }}
                                ratio={mediaDetails.height / mediaDetails.width}>
                <LazyImage
                    sizes="auto"
                    srcset={post.work.afbeelding.srcSet}
                    src={mediaDetails.sizes.find(({ name }) => name === "placeholder").sourceUrl}
                />

                <div className="overlay">
                    <p>
                        {post.title}
                        <br />
                        {post.work.ondertitel}
                    </p>
                </div>
            </RatioPreservingBox>
    }

    return (
        <>
            {index === 0 ? <div className={`work-item item spacer`} key="spacer" /> : null}

            <div
                className={"work-item item " + getRandomClass("random-positioning-")}
                key={post.id}
            >
                <Link
                    to={`/work${post.uri}`}
                    className="item-postioning"
                >
                    {item}
                </Link>
            </div>
        </>
    )
}

export default WorkItem
